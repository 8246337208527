import { DETAIL_SCREEN_KBN } from '@/common/const.js'
import AmountEdit from './AmountEditInfo'

export function setDatas(obj) {
  let para = obj.getDatas
  // 当画面 <= 親画面
  obj.selectRowIdx = para.detailRowIndex
  obj.detailScreenKbn = para.detailScreenKbn
  obj.tabKbn = para.tabKbn
  if (obj.detailScreenKbn == DETAIL_SCREEN_KBN.NEW) {
    //追加
    obj.amountEdit = new AmountEdit()
    obj.amountEdit.detailScreenKbn = DETAIL_SCREEN_KBN.NEW
    obj.amountEdit.tabKbn = para.tabKbn
    obj.amountEdit.amountMoney = null
    obj.amountEdit.taxPercentage = 0
  } else {
    //編集
    obj.amountEdit.costId = para.selectedDetail[0].cost_id
    obj.amountEdit.tariffId = para.selectedDetail[0].tariff_id
    obj.amountEdit.freightItemId = para.selectedDetail[0].freight_item_id
    obj.amountEdit.freightItemName = para.selectedDetail[0].freight_item_name
    obj.amountEdit.amountMoney = para.selectedDetail[0].amount_money
    obj.amountEdit.taxPercentage = para.selectedDetail[0].tax_percentage
    obj.amountEdit.detailScreenKbn = DETAIL_SCREEN_KBN.DETAIL
    obj.amountEdit.tabKbn = para.tabKbn
  }

  // #2786
  // 荷主の部署グループ
  obj.amountEdit.ownerDepartmentGroup = para.ownerDepartmentGroup

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

export async function setting(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.$bvModal.hide('amount-edit-modal')
  obj.$emit('after-close-set', obj.amountEdit)
}
