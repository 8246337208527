<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="amountEdit"
            :inputComponents="inputComponents"
          >
            <template v-slot:tab1TableDown>
              <div v-for="(n, i) in 14" v-bind:key="i + Math.random()">
                <br />
              </div>
            </template>
          </BaseTabGroup>
          <br />
        </div>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- 設定ボタン -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.setting"
          @click="setting(obj)"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!-- 運賃項目マスタモーダル -->
    <FreightItemModal
      id="freight-item-modal-edit"
      type="entry"
      displayModals="two"
      :selectedSearchCondition="freightItemModalSearchCondition"
      @after-close-set="afterCloseSetFreightItem"
      @after-close-unset="afterCloseUnsetFreightItem"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
  </div>
</template>

<script>
import AmountEditInfo from './AmountEditInfo.js'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { SCREEN_ID, INTEGER_MAX_MIN, COST_TYPE } from '@/common/const.js'
import { setDatas, setting } from '@/accounting/payment/AmountEditModal.js'

const screenId = SCREEN_ID.AMOUNT_EDIT_MODAL

export default {
  name: 'amountEditModal',

  components: {
    FreightItemModal: () => import('@/master/freightItem/FreightItemModal.vue'),
  },

  props: {
    selectedItem: {
      type: Object,
    },
    getDatas: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    getDatas() {
      setDatas(this)
    },
  },

  data() {
    return {
      amountEdit: new AmountEditInfo(),
      /**
       * 作業データリスト.
       * @type {Array}
       */
      //modalKbn: 0,
      //screenKbn: -1,
      obj: this,
      selectRowIdx: -1,
      detailScreenKbn: -1,
      tabKbn: 0,
      freightItemModalSearchCondition: {},
    }
  },

  computed: {
    inputComponents() {
      return [
        {
          // タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // ID
              type: 'text',
              id: 'costId',
              columnInfo: this.detailInfo.cost_id,
            },
            {
              // タリフNo
              type: 'text',
              id: 'tariffNo',
              columnInfo: this.detailInfo.tariff_no,
            },
            {
              // 金額
              type: 'text',
              id: 'amountMoney',
              columnInfo: this.detailInfo.amount_money,
              minValue: INTEGER_MAX_MIN.MINUS_MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                //this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.NUMBER_POSITIVE_NEGATIVE,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 運賃項目
              type: 'referenceCodeName',
              codeId: 'freightItemId',
              nameId: 'freightItemName',
              codeColumnInfo: this.detailInfo.freight_item_id,
              nameColumnInfo: this.detailInfo.freight_item_name,
              btnColumnInfo: this.detailInfo.sub_modal_freight_item,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                // 請求支払区分参照時、請求支払区分デフォルト設定
                this.freightItemModalSearchCondition = {}
                if (this.tabKbn == 2) {
                  // 請求
                  this.freightItemModalSearchCondition.costType =
                    COST_TYPE.BILLING
                }
                if (this.tabKbn == 3) {
                  // 支払
                  this.freightItemModalSearchCondition.costType =
                    COST_TYPE.PAYMENT
                }
                // #2786
                // 荷主の部署グループ
                this.freightItemModalSearchCondition.departmentGroup = this.amountEdit.ownerDepartmentGroup

                this.openSubModal(this, 'freight-item-modal-edit')
              },
            },
          ],
        },
      ]
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
  },

  methods: {
    getListOptions,
    setDatas,
    openSubModal,
    setting,

    /**
     * 運賃項目マスタから取得データ設定
     */
    afterCloseSetFreightItem(selectedItem) {
      this.amountEdit.freightItemId = selectedItem.freight_item_id
      this.amountEdit.freightItemName = selectedItem.freight_item_name
      this.amountEdit.taxPercentage = selectedItem.tax_percentage
    },
    afterCloseUnsetFreightItem() {
      this.amountEdit.freightItemId = null
      this.amountEdit.freightItemName = null
      this.amountEdit.taxPercentage = 0
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style scoped></style>
