import { render, staticRenderFns } from "./AmountEditModal.vue?vue&type=template&id=c64d16d6&scoped=true&"
import script from "./AmountEditModal.vue?vue&type=script&lang=js&"
export * from "./AmountEditModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c64d16d6",
  null
  
)

export default component.exports