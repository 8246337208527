/**
 * 金額設定情報データ.
 * @class
 * @exports
 */
export default class amountEdit {
  //--------------TAB1------------//
  // validBeginDate = null
  // adjustmentMoneyRate = null
  // surchargeScreenKbn = -1
  // surchargeId
  costId
  tariffId
  //金額
  amountMoney = 0
  //運賃項目
  freightItemId = null
  freightItemName = null
  taxPercentage = 0
  detailScreenKbn = -1
  tabKbn = -1

  // #2786
  // 荷主の部署グループ
  // （運賃確定詳細画面から設定する情報）
  ownerDepartmentGroup = null
}
